/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.toggle-wrapper {
    margin: 0.5em auto;
  }
  
  .toggle-wrapper label {
    padding: 0.3em;
    font-size: 17px;
    display: block;
  }
  
  .toggleContainer {
    width: 100%;
    border-radius: 8px;
    position: relative;
    background: #ffffff;
    border: 1px solid #eeeeee;
    display: flex;
    height: 28px;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  
  .toggleContainer .toggleOption {
    display: inline-block;
    position: relative;
    z-index: 1;
    text-align: center;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    color: #000;
    font-size: 1em;
    border-radius: 8px;
    display: flex;
    justify-content: center;   
    padding: 2px 2px 2px 2px;
  }
  
  .toggleContainer .toggleOption.selected {
    color: #ffffff;
    cursor: initial;
  }
  
  .toggleContainer .toggle {
    position: absolute;
    height: 100%;
    bottom: 0;
    left: 0;
    transition: all 0.4s ease-in-out;
    background: #3b82f6;
    border-radius: 8px;
    display: flex;
    justify-content: center;
  }
  
  .optionDisabled {
    background: #e6e6e6;
    cursor: no-drop !important;
  }
  
